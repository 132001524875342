"use client";

import { RailsConstantsKeys } from "rivals/redux/rails/reducer";
import useGetCCPAFooter from "rivals/services/getCCPAFooter/useGetCCPAFooter";
import useGetCurrentUser from "rivals/services/getCurrentUser/useGetCurrentUser";
import useGetRailsConstants from "rivals/services/getRailsConstants/useGetRailsConstants";
import useGetSiteFromLocation from "rivals/services/getSiteFromLocation/useGetSiteFromLocation";
import type { GetRenderAdsForUserResponse } from "./types";

// TODO: https://griosf.atlassian.net/browse/RVLS-8914
/**
 * Makes API calls to get all necessary data to determine if ads
 * should be rendered for the user.
 *
 * Only for use on the client
 */
export function useGetRenderAdsForUser(): GetRenderAdsForUserResponse {
  const { data: adSettings } = useGetRailsConstants({
    components: [RailsConstantsKeys.ADS_SETTINGS]
  });

  // Get the current user to determine if they have ad lite
  const { data: user } = useGetCurrentUser();

  // Get the CCPA footer data to determine if the user has opted out of 3rd party sharing
  const { data: consentData } = useGetCCPAFooter();

  const { data: site } = useGetSiteFromLocation();

  return {
    adSettings: adSettings?.[RailsConstantsKeys.ADS_SETTINGS],
    consentData,
    site: site,
    user
  };
}
