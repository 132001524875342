"use client";

import Script from "next/script";
import { JSX } from "react";
import errorService from "rivals/services/error";
import { useGetRenderAdsForUser } from "rivals/shared/app/client";
import { DISTRO_SCALE_SCRIPT_PATH } from "rivals/shared/constants";

const DISTRO_SCRIPT_ID = "distroScript";

/**
 * Script component to render DistroScale ads
 * The primary responsibility of whether we can render DS ads belongs here
 * If we do not inject the script based on the users permissions and subscriptions
 * the user will not see ads independently of whether or not the DistroScaleAd component
 * is rendered
 *
 * @returns Next.js Script component
 */
export default function DistroScale(): JSX.Element | null {
  const data = useGetRenderAdsForUser();

  const shouldRenderScript =
    !data.user?.user?.adLite && data.consentData?.thirdPartyEmbedConsent;

  if (!shouldRenderScript) {
    return null;
  }

  return (
    <Script
      id={DISTRO_SCRIPT_ID}
      onError={(e: Error) => {
        if (
          !process.env.NEXT_PUBLIC_AIRBRAKE_FILTERS?.split(",").includes(
            "distroscale"
          )
        ) {
          errorService.capture(e);
        }
      }}
      src={DISTRO_SCALE_SCRIPT_PATH}
      strategy="afterInteractive"
    />
  );
}
