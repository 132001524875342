"use client";

import Cookies from "js-cookie";
import isEmpty from "lodash/isEmpty";
import { JSX, useCallback, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { AlertTypes } from "rivals/redux/alert/reducer";
// eslint-disable-next-line no-restricted-imports
import { configureClientComponentApi } from "rivals/services/configureClientComponentApi";
import useGetCCPAFooter from "rivals/services/getCCPAFooter/useGetCCPAFooter";
import useGetCurrentUser from "rivals/services/getCurrentUser/useGetCurrentUser";
import { Response as ConfirmationEmailResponse } from "rivals/services/requestConfirmationEmail/types";
import {
  AlertContext,
  AlertDispatchContext
} from "rivals/shared/app/alertContext";
import {
  LAPSED_SUB_ALERT_DURATION_SECONDS,
  PATHS,
  RESEND_EMAIL,
  RIVALS_AUTH_COOKIE,
  UNCONFIRMED_ALERT
} from "rivals/shared/constants";
import { isCurrentUserType } from "rivals/shared/interfaces/User";
import { Props } from "./types";

/**
 * Wrapper component that performs a current user check on page load
 * and displays alerts based on the user's status.
 *
 * Redux-free version for use in the App Router only
 */
export default function AuthenticationWrapper({
  children
}: Props): JSX.Element {
  const { alerts } = useContext(AlertContext);
  const dispatch = useContext(AlertDispatchContext);

  const [pendingAlertId, setPendingAlertId] = useState<string>();

  const { data: ccpaFooterConstants } = useGetCCPAFooter();

  const { data } = useGetCurrentUser();
  const authenticated = !!data?.user;

  // START: request email flow
  const requestConfirmationEmail = async (
    email: string
  ): Promise<ConfirmationEmailResponse> => {
    const apiService = configureClientComponentApi();
    return await apiService.requestConfirmationEmail(email);
  };

  /**
   * If the user has not confirmed their email, show the Alert
   */
  const showPendingAlert = useCallback((): void => {
    const id = uuidv4();
    setPendingAlertId(id);

    dispatch({
      payload: {
        id,
        linkText: RESEND_EMAIL,
        message: UNCONFIRMED_ALERT,
        status: "pending"
      },
      type: "SHOW_ALERT"
    });
  }, [dispatch, setPendingAlertId]);

  /**
   * If the user triggers the flow by clicking the button in the Alert
   * Request the confirmation email
   * Show the success message Alert
   * Clear the local alert state
   * Hide the Alert
   */
  const clearPendingAlert = useCallback(async (): Promise<void> => {
    if (!data?.user || !pendingAlertId) {
      return;
    }

    const response = await requestConfirmationEmail(data.user.email);

    dispatch({
      payload: {
        message: response.message
      },
      type: "SHOW_ALERT"
    });

    // Clear the pending alert
    setPendingAlertId(undefined);
    dispatch({ payload: { id: pendingAlertId }, type: "HIDE_ALERT" });
  }, [dispatch, pendingAlertId, setPendingAlertId, data]);

  /**
   * If the status of known alert changes, clear the alert
   */
  useEffect(() => {
    // Respond on change to pending alert
    const alert = alerts.find(
      alert => alert.id === pendingAlertId && alert.status === "resolved"
    );

    if (alert) {
      clearPendingAlert();
    }
  }, [alerts, clearPendingAlert, pendingAlertId]);
  // END: request email flow

  const authLogger = useCallback(
    function (msg = ""): void {
      if (process.env.NEXT_PUBLIC_AUTH_LOGGER === "true") {
        const logNameMsg = `AUTH WRAPPER: ${msg}`;
        const baseCookie =
          Cookies.get(RIVALS_AUTH_COOKIE) !== undefined ? true : false;
        const authState = {
          isAuthenticated: authenticated,
          rivalsAuthenticated: baseCookie
        };
        console.log(logNameMsg, "\n", authState); // eslint-disable-line no-console
      }
    },
    [authenticated]
  );

  useEffect(() => {
    // Alert the user to buy subscription by showing cta banner
    if (ccpaFooterConstants?.showCtaBanner) {
      dispatch({
        payload: {
          hasCloseButton: false,
          href: ccpaFooterConstants.ctaBannerUrl || PATHS.SIGN_UP,
          linkFillsAlert: true,
          linkText: ccpaFooterConstants.ctaBannerTxt || "",
          message: "",
          persisted: true,
          type: AlertTypes.SITE_ADAPTIVE
        },
        type: "SHOW_ALERT"
      });
    }
    // Alert the user if they need to confirm their email
    if (data?.user?.email && !data?.user?.emailConfirmed && !pendingAlertId) {
      showPendingAlert();
    }
    // Alert the user if they have lapsed subscriptions
    if (
      data?.user &&
      isCurrentUserType(data?.user) &&
      !isEmpty(data?.user?.lapsedSubs)
    ) {
      authLogger("showAlert (lapsedSubs)");
      const {
        user: { lapsedSubs }
      } = data;
      if (!lapsedSubs) {
        return;
      }
      lapsedSubs.forEach(sub => {
        const { href, linkText, message } = sub;
        dispatch({
          payload: {
            durationInSeconds: LAPSED_SUB_ALERT_DURATION_SECONDS,
            href,
            linkText,
            message
          },
          type: "SHOW_ALERT"
        });
      });
    }
  }, [
    authenticated,
    authLogger,
    ccpaFooterConstants,
    dispatch,
    pendingAlertId,
    showPendingAlert,
    data
  ]);

  return <>{children}</>;
}
