"use client";

import React from "react";
import useGetCCPAFooter from "rivals/services/getCCPAFooter/useGetCCPAFooter";
import JurisdictionLinks from "./JurisdictionLinks";
import FooterLinks from "./Links";
import type { OwnProps } from "./types";

export default function Footer({
  areArticleLinks: areArticleLinks = false,
  giftingEnabled
}: OwnProps): React.JSX.Element | null {
  const { data: CCPAFooterConstants } = useGetCCPAFooter();

  const jurisdictionLinks = (
    <JurisdictionLinks
      areArticleLinks={areArticleLinks}
      ccpaFooterConstants={CCPAFooterConstants}
    />
  );

  return (
    <FooterLinks
      areArticleLinks={areArticleLinks}
      giftingEnabled={giftingEnabled}
    >
      {jurisdictionLinks}
    </FooterLinks>
  );
}
