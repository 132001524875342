"use client";

import classNames from "classnames";
import Cookies from "js-cookie";
import dynamic from "next/dynamic";
import { usePathname } from "next/navigation";
import { JSX, useEffect, useState } from "react";
import LogoSection from "rivals/components/app/Layout/Header/LogoSection";
import CustomButton from "rivals/components/shared/CustomButton";
import { RailsConstantsKeys } from "rivals/redux/rails/reducer";
// eslint-disable-next-line no-restricted-imports
import { configureClientComponentApi } from "rivals/services/configureClientComponentApi";
import useGetCurrentUser from "rivals/services/getCurrentUser/useGetCurrentUser";
import type { Response as RailsConstantsResponse } from "rivals/services/getRailsConstants/types";
import useLogoutUser from "rivals/services/logoutUser/useLogoutUser";
import {
  RIVALS_AUTH_COOKIE,
  ROOT_SITE_IDENTIFIER,
  USER_ROLES
} from "rivals/shared/constants";
import styles from "./Header.module.scss";
import Nav from "./Nav";
import { fixNavLinksforEnv } from "./Nav/utils";
import type { NavOptions, Props } from "./types";
import { parseNavItems } from "./utils";

// Preventing Server Side Rendering (SSR) to avoid potential caching of username
const AccountLinks = dynamic(
  () => import("rivals/components/app/Layout/Header/AccountLinks"),
  { ssr: false }
);

const COMPONENTS = [RailsConstantsKeys.NAV_BAR];

export default function Header({
  selectedSite,
  useLegacyNavTemplate
}: Props): JSX.Element | null {
  const pathname = usePathname();
  const { data } = useGetCurrentUser();

  const onLogoutUser = async (): Promise<void> => {
    Cookies.remove(RIVALS_AUTH_COOKIE);
  };

  const { trigger } = useLogoutUser(onLogoutUser);

  const [railsConstants, setRailsConstants] = useState<
    RailsConstantsResponse
  >();

  const [navItems, setNavItems] = useState<NavOptions>();

  const fetchRailsConstants = async (): Promise<void> => {
    const apiService = configureClientComponentApi();
    const response = await apiService.getRailsConstants({
      components: COMPONENTS
    });
    setRailsConstants(response);
  };

  useEffect(() => {
    fetchRailsConstants();
  }, []);

  const navConstants = railsConstants?.[RailsConstantsKeys.NAV_BAR];

  const isAdminPage = pathname?.startsWith("/admin");
  const isAdminReportsPage = pathname?.startsWith("/admin/reports");

  const isNational =
    ROOT_SITE_IDENTIFIER == selectedSite?.shortName?.toLowerCase();

  let finalNavItems = navItems;

  if (isAdminPage && navConstants) {
    finalNavItems = parseNavItems(navConstants?.adminLinks);
  }

  const [collapsed, toggleCollapsed] = useState(true);

  const showReportingAdmin =
    data?.user?.role === USER_ROLES.REPORTING_ADMIN && isAdminReportsPage;

  useEffect(() => {
    toggleCollapsed(true);
  }, [pathname]);

  useEffect(() => {
    const fetchNavItems = async (subdomain?: string): Promise<void> => {
      const apiService = configureClientComponentApi();
      const response = await apiService.getNavItems(subdomain);
      setNavItems(fixNavLinksforEnv(response));
    };

    if (!navItems) {
      fetchNavItems(selectedSite?.subdomain);
    }
  }, [navItems, selectedSite]);

  const collapsedClass = classNames(
    {
      [styles.collapsed]: collapsed,
      [styles.visible]: !collapsed,
      [styles.hideSecondaryNav]: showReportingAdmin
    },
    styles.header
  );

  const onBurgerClick = (): void => {
    toggleCollapsed(!collapsed);
  };

  return (
    <div className={collapsedClass}>
      <div
        className={styles.headerMenuContainer}
        style={
          selectedSite
            ? {
                backgroundColor: `#${selectedSite.primaryColor}`,
                borderColor: `#${selectedSite.secondaryColor}`
              }
            : {}
        }
      >
        <div className={styles.headerMenu}>
          <div className={styles.headerLogoWrapper}>
            {!showReportingAdmin && (
              <CustomButton
                ariaLabel="mobile navigation fold"
                className={styles.headerNavMobile}
                icon={<div className={styles.navMobileDropdown} />}
                onClick={onBurgerClick}
              />
            )}
            <LogoSection isNational={isNational} selectedSite={selectedSite} />
          </div>
          <div>
            <AccountLinks
              logout={() => {
                trigger();
              }}
              site={selectedSite}
            />
          </div>
        </div>
      </div>
      {!showReportingAdmin && (
        <div className={styles.headerNavLargeBreakpoint}>
          <Nav
            collapsed={collapsed}
            navBarRailsConstants={railsConstants?.navBar}
            navItems={finalNavItems}
            useLegacyTemplate={useLegacyNavTemplate}
          />
        </div>
      )}
    </div>
  );
}
