"use client";

import findIndex from "lodash/findIndex";
import { useReducer } from "react";
import { v4 as uuidv4 } from "uuid";
import type { State } from "rivals/redux/alert/reducer";
import {
  AlertContext,
  AlertDispatchContext
} from "rivals/shared/app/alertContext";
import type { AlertAction, Props } from "./types";
import { areAlertsEqual } from "./utils";

function reducer(state: State, action: AlertAction): State {
  switch (action.type) {
    case "CLEAR_ALERTS": {
      return state;
    }
    case "HIDE_ALERT": {
      const alerts = [...state.alerts];
      const filteredAlerts = alerts.filter(
        alert => alert.id !== action.payload.id
      );
      return {
        ...state,
        alerts: filteredAlerts
      };
    }
    case "SHOW_ALERT": {
      const alerts = [...state.alerts];
      const { payload } = action;
      if (!alerts.find(alert => areAlertsEqual(alert, payload))) {
        alerts.push({
          ...payload,
          id: payload.id || uuidv4()
        });
      }
      return {
        ...state,
        alerts
      };
    }
    case "UPDATE_ALERT_STATUS": {
      const alerts = [...state.alerts];
      const { payload } = action;
      const alertIdx = findIndex(alerts, alert => alert.id === payload.id);
      alerts[alertIdx].status = "resolved";
      return {
        ...state,
        alerts
      };
    }
    default:
      return state;
  }
}

const AlertsProvider = ({
  children,
  initialState = { alerts: [] }
}: Props): React.JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AlertContext.Provider value={state}>
      <AlertDispatchContext.Provider value={dispatch}>
        {children}
      </AlertDispatchContext.Provider>
    </AlertContext.Provider>
  );
};

export default AlertsProvider;
