"use client";

import { mediaStyles } from "rivals/media";

// https://github.com/artsy/fresnel/blob/main/examples/nextjs/app-router/src/components/RootHead.tsx
function RootHead(): React.JSX.Element {
  return (
    <head>
      <style
        dangerouslySetInnerHTML={{ __html: mediaStyles }}
        key="fresnel-css"
        type="text/css"
      />
    </head>
  );
}

export default RootHead;
