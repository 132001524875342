"use client";

import classNames from "classnames";
import { usePathname } from "next/navigation";
import { useState } from "react";
import MediaWrapper from "rivals/components/shared/Media";
import Menu from "rivals/components/shared/Menu";
import { MEDIA_BREAKPOINTS } from "rivals/media";
// eslint-disable-next-line no-restricted-imports
import { configureClientComponentApi } from "rivals/services/configureClientComponentApi";
import { Response as NavTeamsResponse } from "rivals/services/fetchTeamsNavItems/types";
import useGetCurrentUser from "rivals/services/getCurrentUser/useGetCurrentUser";
import {
  isAdminProtectedPage,
  isUserProtectedPage
} from "rivals/shared/utils/routes";
import { buildMenu, buildMobileMenu } from "./MenuBuilder";
import styles from "./Nav.module.scss";
import strings from "./strings";
import type { Props } from "./types";
import { getSelectedMenuItem, navElements } from "./utils";

/**
 * Redux-free version of Nav for use in the App Router
 */
export default function Nav({
  collapsed,
  navItems,
  useLegacyTemplate = false,
  navBarRailsConstants
}: Props): React.JSX.Element {
  const path = usePathname() ?? "";
  const [teamsNavItems, setTeamsNavItems] = useState<NavTeamsResponse>();

  const menuItems = navElements({
    navBarRailsConstants,
    navItems,
    path,
    teamOptions: teamsNavItems
  });
  const { data } = useGetCurrentUser();
  const authenticated = !!data?.user;
  const desktopItems = buildMenu(useLegacyTemplate, menuItems);
  const mobileItems = buildMobileMenu(authenticated, desktopItems);

  const fetchTeamsNavItems = async (): Promise<void> => {
    try {
      const apiService = configureClientComponentApi();
      const response = await apiService.fetchTeamsNavItems();
      setTeamsNavItems(response);
    } catch (error) {
      return;
    }
  };

  const onOpenMenuChange = (openKeys: string[]): void => {
    if (!isUserProtectedPage(path) && !isAdminProtectedPage(path)) {
      const teamsMenuOpen = openKeys.some(key =>
        key.toLowerCase().includes(strings.navTitles.teams.toLowerCase())
      );
      if (teamsMenuOpen && !teamsNavItems) {
        fetchTeamsNavItems();
      }
    }
  };

  return (
    <>
      <MediaWrapper greaterThanOrEqual={MEDIA_BREAKPOINTS.LG}>
        <div
          className={classNames({
            [styles.navContainerHorizontalMenu]: !useLegacyTemplate,
            [styles.navContainerHorizontalMenuLegacy]: useLegacyTemplate
          })}
        >
          <Menu
            defaultSelectedKeys={[getSelectedMenuItem(menuItems, path)]}
            items={desktopItems}
            mode="horizontal"
            onOpenChange={onOpenMenuChange}
          />
        </div>
      </MediaWrapper>

      <MediaWrapper lessThan={MEDIA_BREAKPOINTS.LG}>
        <Menu
          className={classNames(styles.navInlineMenu, {
            [styles.collapsed]: collapsed
          })}
          items={mobileItems}
          mode="inline"
          onOpenChange={onOpenMenuChange}
        />
      </MediaWrapper>
    </>
  );
}
