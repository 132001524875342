import { SWRResponse } from "swr";
import useQuery, {
  UseQueryOptions
} from "rivals/shared/hooks/useQuery/useQuery";
import type { Request, Response } from "./types";

export default function useGetRailsConstants(
  requestParams: Request,
  options?: UseQueryOptions
): SWRResponse<Response> {
  const params = requestParams.components
    .map(component => `components[]=${component}`)
    .join("&");

  const url = `/api/v2/react_props?${params}`;

  return useQuery<Response>(url, options);
}
